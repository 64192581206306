import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

import { useStore } from 'vuex';
import { computed } from 'vue';

// Components
import { BaseContainer, BaseGrid, BaseColumn } from '@/components/primitives';
import { BaseButton, BaseSpacer, BaseText } from '@/components/partials';
import { BaseForm, BaseInput } from '@/components/partials/forms';

// Helpers
import { HelperFormErrors } from '@/helpers/form';

// Types
import type { ComputedRef } from 'vue';
import type { SelectBinForm } from '@/types/form';


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const store = useStore();

let message: ComputedRef<string> = computed(() => store.state.bin.message);
const selectBinForm: SelectBinForm = {
  name: '',
  errors: new HelperFormErrors(),
};

const storeBinLocation: void = () => store.dispatch('bin/selectBin', selectBinForm);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), null, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseText), {
        tag: "h1",
        size: { default: '3xl' }
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Select a bin")
        ])),
        _: 1
      }),
      _createVNode(_unref(BaseSpacer), { size: "4" }),
      _createVNode(_unref(BaseForm), { onFormSubmitted: storeBinLocation }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseGrid), null, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseColumn), { span: { tablet: '6' } }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseInput), {
                    label: "Bin name",
                    name: "bin-name",
                    value: selectBinForm.name,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((selectBinForm.name) = $event)),
                    "onReset:errors": _cache[1] || (_cache[1] = ($event: any) => (selectBinForm.errors.clear('bin-name'))),
                    error: selectBinForm.errors.get('bin-name')
                  }, null, 8, ["value", "error"]),
                  _createVNode(_unref(BaseSpacer)),
                  (_unref(message))
                    ? (_openBlock(), _createBlock(_unref(BaseText), {
                        key: 0,
                        tags: "p"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(message)), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(BaseSpacer), { size: "2" }),
          _createVNode(_unref(BaseColumn), null, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), {
                color: "success",
                size: "large",
                type: "submit"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("span", null, "Select bin", -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})